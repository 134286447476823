import { render, staticRenderFns } from "./LivePollPresentationWidget.vue?vue&type=template&id=73168492&scoped=true&"
import script from "./LivePollPresentationWidget.vue?vue&type=script&lang=ts&"
export * from "./LivePollPresentationWidget.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/live-session/LivePollPresentationWidget.scss?vue&type=style&index=0&id=73168492&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73168492",
  null
  
)

export default component.exports