
























import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import Session from '@/models/graphql/Session';
import QrLiveComponent from '@/components/live-session/QrLiveComponent.vue';
import Poll from '@/models/graphql/Poll';
import { PollFilter } from '@/graphql/_Filters/PollFilter';
import LivePollComponent from '@/components/live-session/LivePollComponent.vue';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import {
  addDays, addHours, addMinutes, addSeconds, differenceInSeconds, format, getUnixTime,
} from 'date-fns';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import GenericEvent from '@/utils/types/GenericEvent';
import PollAnswer from '@/models/graphql/PollAnswer';
import PollUserAnswer from '@/models/graphql/PollUserAnswer';

const notificationStore = namespace('NotificationStore');

type PollAction = 'open' | 'close';

@Component({
  components: {
    QrLiveComponent,
    LivePollComponent,
  },
})
export default class LivePollPresentationWidget extends mixins(VueBaseWidget, VueBaseNotify, VueRegisterStoreWidget) {
  protected baseStoreName = 'PollBaseStore';

  @Prop({
    required: false,
    default: null,
  })
  private entityCode!: string;

  @Prop({
    required: false,
    default: null,
  })
  private qrCodeUrl!: string;

  @notificationStore.Mutation
  private unsubscribeGenericEventPointer!: (channel: string) => void;

  @notificationStore.Action
  private triggerGenericEvent!: (params: {
    entityId: string;
    type: string;
    extra: string;
    channels: string[];
  }) => void;

  @notificationStore.Action
  private genericEvent!: (payload: {
    channel: string;
    customCallback?: (event: GenericEvent) => void;
  }) => void;

  private poll: Poll | null = null;

  private session: Session | null = null;

  private action: PollAction | null = null;

  private closeIn: string | null = null;

  private showResult = true;

  private get route(): string {
    if (this.qrCodeUrl) {
      return this.qrCodeUrl;
    }
    const currentURL = window.location;
    if (this.session) {
      return currentURL.origin
          + this.$router.resolve({
            name: 'session-detail',
            params: { sessionId: this.session?.uid },
          }).href;
    }
    return '';
  }

  private get uid(): string {
    return WidgetHelper.entityCode(this.entityCode, this.$route as unknown as Location);
  }

  private get isPollClosed(): boolean {
    if (this.poll) {
      if (!this.poll.endTime && this.poll.startTime) {
        return false;
      }
      const now = DateTimeHelper.getCurrentDateTime();
      const endTime = this.poll.endTime ? DateTimeHelper.toLocal(new Date(this.poll.endTime)) : null;
      if (endTime) {
        return now > endTime;
      }
      return true;
    }
    return true;
  }

  private get isSessionRunning(): boolean {
    const now = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
    if (!this.session?.startTime) {
      return false;
    }
    if (!this.session?.endTime) {
      return true;
    }
    const start = DateTimeHelper.toUTC(new Date(this.session.startTime));
    const end = DateTimeHelper.toUTC(new Date(this.session.endTime));
    const isStarted = differenceInSeconds(start, now) <= 0;
    const isNotEnded = differenceInSeconds(end, now) >= 0;
    return isStarted && isNotEnded;
  }

  created(): void {
    this.setDataConfig();
    this.readUrlQueries();
    this.notifyEvents = [
      NotificationEventType.POLL_ANSWER,
      NotificationEventType.POLL_PUBLISH,
      NotificationEventType.POLL_CLOSE,
      NotificationEventType.POLL_RESET,
    ];
  }

  @Watch('isSessionRunning')
  readUrlQueries(): void {
    if (!this.isSessionRunning) {
      return;
    }
    if (this.$route.query.action) {
      this.action = this.$route.query.action as PollAction;
    }
    if (this.$route.query.close_in && this.action === 'open') {
      this.closeIn = this.$route.query.close_in as string;
    }
    if (this.$route.query.show_result) {
      this.showResult = String(this.$route.query.show_result)
        .toLowerCase() === 'true';
    }
  }

  @Watch('entityCode')
  init(): void {
    if (this.uid) {
      this.loadPoll()
        .then((isPollLoaded: boolean) => {
          if (!isPollLoaded) return;
          if (this.action === 'open' && this.isPollClosed) {
            this.openPoll()
              .then((openedPoll) => {
                if (openedPoll) {
                  this.poll = { ...this.poll, ...openedPoll };
                  this.triggerGenericEvent({
                    channels: [
                      `poll-channel-${this.poll?.session?.uid}`,
                      `poll-channel-${this.poll?.session?.parentSession?.uid}`,
                    ],
                    type: NotificationEventType.POLL_PUBLISH,
                    entityId: openedPoll.uid,
                    extra: JSON.stringify({
                      user: this.authUser.uid,
                    }),
                  });
                  if (this.closeIn && this.poll?.session) {
                    const endDate = this.convertCloseInToDate()
                      .getTime()
                            - DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime())
                              .getTime();
                    setTimeout(() => {
                      this.triggerGenericEvent({
                        channels: [
                          `poll-channel-${this.poll?.session?.uid}`,
                          `poll-channel-${this.poll?.session?.parentSession?.uid}`,
                        ],
                        type: NotificationEventType.POLL_CLOSE,
                        entityId: openedPoll.uid,
                        extra: '',
                      });
                    }, endDate);
                  }
                }
              });
          } else if (this.action === 'close' && !this.isPollClosed) {
            this.closePoll()
              .then((closedPoll) => {
                if (closedPoll) {
                  this.poll = { ...this.poll, ...closedPoll };
                  this.triggerGenericEvent({
                    channels: [
                      `poll-channel-${this.poll?.session?.uid}`,
                      `poll-channel-${this.poll?.session?.parentSession?.uid}`,
                    ],
                    type: NotificationEventType.POLL_CLOSE,
                    entityId: this.poll.uid,
                    extra: '',
                  });
                }
              });
          }
        });
    }
  }

  async loadPoll(): Promise<boolean> {
    if (this.uid) {
      const response = await this.getPoll({
        filter: {
          uid: this.uid,
        },
        fragmentName: 'pollFullFragment',
      });
      if (response) {
        this.poll = response;
        if (response.session) {
          this.session = response.session;
          this.genericEvent({ channel: `poll-channel-${this.session?.uid}` });
        }
        return true;
      }
      return false;
    }
    return Promise.resolve(false);
  }

  beforeDestroy(): void {
    if (this.session) {
      this.unsubscribeGenericEventPointer(`poll-channel-${this.session.uid}`);
    }
  }

  setMyAnswer({
    pollUid,
    myAnswer,
    myOldAnswer,
  }: {
    pollUid: string;
    myAnswer: PollUserAnswer;
    myOldAnswer: PollAnswer | null;
  }): void {
    if (this.poll && pollUid === this.poll.uid) {
      const localPoll = this.poll;
      // eslint-disable-next-line no-underscore-dangle
      localPoll._myAnswer = [myAnswer];
      if (myOldAnswer) {
        if (localPoll.pollAnswers) {
          const oldLocalPollAnswer = localPoll.pollAnswers.find((pa) => pa.uid === myOldAnswer.uid);
          if (oldLocalPollAnswer && oldLocalPollAnswer.answerCount && oldLocalPollAnswer.answerCount > 0) {
            oldLocalPollAnswer.answerCount -= 1;
          }
        }
        if (localPoll.answerCountByAnswerId) {
          const oldLocalAnswerCountByAnswerIndex = localPoll.answerCountByAnswerId
            .findIndex((acbId) => acbId.key === `${myOldAnswer.id}`);
          if (oldLocalAnswerCountByAnswerIndex > -1) {
            const value = parseInt(localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value || '0', 10);
            if (value > 1) {
              localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value = `${value - 1}`;
            } else {
              localPoll.answerCountByAnswerId.splice(oldLocalAnswerCountByAnswerIndex, 1);
            }
          }
        }
      }
      if (localPoll.pollAnswers) {
        const newLocalPollAnswer = localPoll.pollAnswers.find((pa) => pa.uid === myAnswer.pollAnswer?.uid);
        if (newLocalPollAnswer) {
          if (newLocalPollAnswer.answerCount) {
            newLocalPollAnswer.answerCount += 1;
          } else {
            newLocalPollAnswer.answerCount = 1;
          }
        }
      }
      if (!localPoll.answerCountByAnswerId) {
        localPoll.answerCountByAnswerId = [];
      }
      const localAnswerCountByAnswerIndex = localPoll.answerCountByAnswerId
        .findIndex((acbId) => acbId.key === `${myAnswer.pollAnswer?.id}`);
      if (localAnswerCountByAnswerIndex > -1) {
        const value = parseInt(localPoll.answerCountByAnswerId[localAnswerCountByAnswerIndex].value || '0', 10);
        localPoll.answerCountByAnswerId[localAnswerCountByAnswerIndex].value = `${value + 1}`;
      } else {
        localPoll.answerCountByAnswerId.push({
          key: `${myAnswer.pollAnswer?.id}`,
          value: '1',
        });
      }
    }
  }

  answerUpdate({
    pollUid,
    pollAnswer,
    oldPollAnswer,
  }: { pollUid: string; pollAnswer: PollAnswer | null; oldPollAnswer: PollAnswer | null }): void {
    if (this.poll && pollAnswer && pollUid === this.poll.uid) {
      const localPoll = this.poll;
      if (oldPollAnswer) {
        if (localPoll.pollAnswers) {
          const oldLocalPollAnswer = localPoll.pollAnswers.find((pa) => pa.uid === oldPollAnswer.uid);
          if (oldLocalPollAnswer && oldLocalPollAnswer.answerCount && oldLocalPollAnswer.answerCount > 0) {
            oldLocalPollAnswer.answerCount -= 1;
          }
        }
        if (localPoll.answerCountByAnswerId) {
          const oldLocalAnswerCountByAnswerIndex = localPoll.answerCountByAnswerId
            .findIndex((acbId) => acbId.key === `${oldPollAnswer.id}`);
          if (oldLocalAnswerCountByAnswerIndex > -1) {
            const value = parseInt(localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value || '0', 10);
            if (value > 1) {
              localPoll.answerCountByAnswerId[oldLocalAnswerCountByAnswerIndex].value = `${value - 1}`;
            } else {
              localPoll.answerCountByAnswerId.splice(oldLocalAnswerCountByAnswerIndex, 1);
            }
          }
        }
      }
      const { pollAnswers } = this.poll;
      const localPollAnswerIndex = pollAnswers ? pollAnswers.findIndex((p) => p.uid === pollAnswer.uid) : -1;

      if (localPollAnswerIndex >= 0) {
        const localPollAnswer = pollAnswers && pollAnswers.length ? pollAnswers[localPollAnswerIndex] : null;
        if (localPollAnswer) {
          localPollAnswer.answerCount = localPollAnswer.answerCount
            ? localPollAnswer.answerCount + 1
            : 1;
          if (localPoll.answerCountByAnswerId && localPoll.answerCountByAnswerId.length) {
            const answerCountsIndex = localPoll.answerCountByAnswerId
              ? localPoll.answerCountByAnswerId
                .findIndex((p) => p.key === (pollAnswer && pollAnswer.id ? pollAnswer.id.toString() : ''))
              : -1;
            if (answerCountsIndex >= 0) {
              const { answerCountByAnswerId } = this.poll;
              if (answerCountByAnswerId && answerCountByAnswerId.length) {
                const { value } = answerCountByAnswerId[answerCountsIndex];
                answerCountByAnswerId[answerCountsIndex].value = (parseInt(value || '0', 10) + 1).toString();
              }
            } else if (pollAnswer && pollAnswer.id) {
              localPoll.answerCountByAnswerId.push({
                key: pollAnswer.id.toString(),
                value: '1',
              });
            }
          } else if (pollAnswer && pollAnswer.id) {
            localPoll.answerCountByAnswerId = [{
              key: pollAnswer.id.toString(),
              value: '1',
            }];
          }
        }
      }
    }
  }

  protected notificationCallback(event: GenericEvent): void {
    if (event && event.type === NotificationEventType.POLL_ANSWER) {
      const extraData = JSON.parse(event.extra);
      if (this.poll && event.entityId === this.poll.uid) {
        const pollAnswer = extraData.pollAnswer ? PollAnswer.hydrate(extraData.pollAnswer) : null;
        const { myAnswer } = extraData;
        const { pollUid } = extraData;
        const oldPollAnswer = extraData.oldPollAnswer ? PollAnswer.hydrate(extraData.oldPollAnswer) : null;
        if (this.authUser && this.authUser.uid === extraData.user.uid) {
          this.setMyAnswer({
            pollUid,
            myAnswer,
            myOldAnswer: oldPollAnswer,
          });
        } else {
          this.answerUpdate({
            pollUid: event.entityId,
            pollAnswer,
            oldPollAnswer,
          });
        }
      }
    } else if (event && event.type === NotificationEventType.POLL_PUBLISH) {
      this.loadPoll();
    } else if (event && event.type === NotificationEventType.POLL_CLOSE) {
      this.onClosePoll();
    } else if (event.type === NotificationEventType.POLL_RESET) {
      this.resetPoll(event.entityId);
    }
  }

  private resetPoll(pollUid: string): void {
    if (this.poll && this.poll.uid === pollUid) {
      this.poll.startTime = undefined;
      this.poll.startTimestamp = undefined;
      this.poll.endTime = undefined;
      this.poll.endTimestamp = undefined;
      this.poll.answerCountByAnswerId = [];
      const answers = this.poll.pollAnswers || [];
      this.poll.pollAnswers = (answers || []).map((pollAnswer) => ({
        ...pollAnswer,
        answerCount: 0,
        pollUserAnswers: [],
      }));
    }
  }

  private openPoll(): Promise<Poll | undefined> {
    if (!this.poll) return Promise.resolve(undefined);
    const now = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
    const newEndTime = this.convertCloseInToDate();
    const updatedPoll = {
      uid: this.poll.uid,
      startTime: format(now, DateTimeHelper.TIME_FORMAT_ISO_8601),
      endTime: format(newEndTime, DateTimeHelper.TIME_FORMAT_ISO_8601),
    };
    return this.updatePoll(updatedPoll);
  }

  private getPoll(payload: { filter: PollFilter; fragmentName: string }): Promise<Poll | undefined> {
    return this.$store.dispatch(`${this.widgetStorePath}/get`, payload);
  }

  private updatePoll(payload: Partial<Poll>): Promise<Poll | undefined> {
    return this.$store.dispatch(`${this.widgetStorePath}/update`, payload);
  }

  private onClosePoll(): void {
    if (this.poll) {
      const endTime = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
      this.poll.endTime = format(endTime, DateTimeHelper.TIME_FORMAT_ISO_8601);
      this.poll.endTimestamp = getUnixTime(endTime);
    }
  }

  private convertCloseInToDate(): Date {
    let newEndTime = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
    if (this.closeIn) {
      const unit = this.closeIn.charAt(this.closeIn.length - 1);
      const timeOperationPerUnitMap: Record<string, (date: number | Date, amount: number) => Date> = {
        s: addSeconds,
        m: addMinutes,
        h: addHours,
        d: addDays,
      };
      if (Object.keys(timeOperationPerUnitMap)
        .includes(unit)) {
        const timeToAdd = Number.parseInt(this.closeIn.substring(0, this.closeIn.length - 1), 10);
        newEndTime = timeOperationPerUnitMap[unit](newEndTime, timeToAdd);
      }
    } else {
      newEndTime = addDays(newEndTime, 9);
    }
    return newEndTime;
  }

  private closePoll(): Promise<Poll | undefined> {
    if (!this.poll) return Promise.resolve(undefined);
    const now = DateTimeHelper.toUTC(DateTimeHelper.getCurrentDateTime());
    const updatedPoll = {
      uid: this.poll.uid,
      endTime: format(now, DateTimeHelper.TIME_FORMAT_ISO_8601),
    };
    return this.updatePoll(updatedPoll);
  }
}
